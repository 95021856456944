header.site-header {
  border-bottom: $divider;

  .site-header-inner {
    padding: 12px 0;

    .logo {
      width: 60px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }

    .account-links a {
      font-family: $secondary-font;
      text-transform: uppercase;
      color: $gray;

      &:hover,
      &:focus {
        color: $gold;
      }
    }
  }
}
