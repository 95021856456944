@import "@/styles/globals.scss";
@import '../../styles/defaults/css-properties';

// Variables
$black: #2e2e2e;
$white: white;
$gold: rgb(199, 159, 39);
$gray: #696969;
$light-gray: #f2f2f2;

$divider: 1px solid #d9d9d9;
$cart-divider: $divider;

$steppers-3-button-background-color: $gold;
$steppers-3-icon-color: white;
$steppers-3-border: 1px solid $gold;
$steppers-3-disabled-icon-color: rgba(255, 255, 255, 0.33);
$steppers-3-count-background-active-color: #b58f1d;
$steppers-3-border-hover-color: $gold;
$steppers-3-button-background-hover-color: white;
$steppers-3-hover-icon-color: $gold;
$steppers-3-box-size: 40px;

$datepicker-icon-color: $gray;
$input-border-color: #cdcdcd;
$input-border-color-hover: #a7a7a7;

$body-color: $black;

$link-hover-color: darken($gold, 5%);

$members-menu-icon-color: $gold;

// Defaults
@import '../../styles/defaults/index';
@import '../../styles/helpers/index';

// Placeholders
%button {
  padding: 10px 15px;
  text-transform: uppercase;
}

%secondary-button {
  border: $divider;
  color: $gray;
  background-color: transparent;
}

%secondary-button-active,
%secondary-button-hover {
  background-color: transparent;
  color: $gold;
  border-color: $gold;
}

%primary-button {
  border: 1px solid $gold;
}

%primary-button-hover {
  background-color: transparent;
  color: $gold;
}

%h1 {
  font-size: 42px;
  text-transform: uppercase;
  color: $gold;
}

%h2 {
  font-size: 28px;
  margin-bottom: 8px;
}

%back-button {
  font-size: 16px;
  text-transform: uppercase;
}

%label {
  font-size: 18px;
}

%input {
  border-color: $input-border-color;
}

%input-hover {
  border-color: $input-border-color-hover;
}

// Base elements
@import 'page';
@import 'elements';
@import 'links-and-buttons';
@import 'form-inputs';
@import 'modals';
@import 'messages';
@import 'badges';

// Components
@import '_header';
@import '_footer';
@import 'filters';
@import 'actions';
@import 'cart';
@import 'selection';
@import 'steppers-3';
@import 'calendar';
@import 'identity-form';
@import 'members-menu';
@import 'legend';

// Routes
@import 'checkout';
@import 'completed';
@import 'event-list';
@import 'buy-membership';
@import 'gift-of-membership';
@import 'forgot-membership';
@import 'event';
@import 'resend-tickets';
@import 'manage-order';
@import 'home';
@import 'my-account';

// Theme
@import 'sass/styles';
@import 'sass/header';
@import 'sass/footer';
